import '@pnr/scss/castagnades.scss';
import '@pnr/scss/castagnades-editor.scss';
import '@pnr/scss/partage.scss';
import '@pnr/scss/partage-editor.scss';
import '@pnr/scss/pnr.scss';
import '@pnr/scss/pnr-editor.scss';
import '@pnr/js/bootstrap.bundle.js';
import { UxCpnt } from '@pnr/js/components/ux_cpnt';
import { AutoHiddingNav } from '@pnr/js/components/AutoHiddingNav';
import { FormCpnt } from '@pnr/js/components/form_cpnt';
import { MapCpnt } from '@pnr/js/components/map_cpnt';
import { Slider } from '@pnr/js/components/Slider';
import { blocMap } from '@pnr/js/components/blocMap';
import { GalleryCpnt } from '@pnr/js/components/Gallery';
import { ApiCpnt } from '@pnr/js/components/Api';

document.addEventListener( "DOMContentLoaded", function () {
    const ObjUxCpnt             = new UxCpnt();
    const ObjFormCpnt           = new FormCpnt();
    const ObjMapCpnt            = new MapCpnt();
    const ObjAutoHiddingNav     = new AutoHiddingNav();
    const ObjSlider             = new Slider();
    const ObjblocMap            = new blocMap();
    const ObjGalleryCpnt        = new GalleryCpnt();
    const ObjApiCpnt        = new ApiCpnt();
})