export class ApiCpnt {
    constructor() {
        this.initContactRequestRoute()
    }

    initContactRequestRoute() {
        var contactCalls = $('[data-role="get-contact"]');

        contactCalls.each(function () {
            var contact = $(this);

            $(this).click(function () {
                var cid = $(this).attr('data-cid');

                $(contact).unbind('click').append('...');

                // Make the request
                $.post({
                    url: Theme.rest_url + "pnr/v1/directory/gcid",
                    data: {cid: cid}
                }).done(function (data) {
                    if (data.eid) {
                        $(contact).unbind('click').hide().after('<p><a class="button clear" href="mailto:' + data.eid + '"><span>' + data.eid + '</span></a></p>');
                    }
                });
            });
        });
    }
}
