export class FormCpnt {

    constructor()
    {
        this.customizeInput()
        this.customizeSelect()
    }

    customizeInput()
    {
        let form_items = $( '.wpcf7 [type="checkbox"], .wpcf7 [type="radio"]' )

        $( form_items ).each( function ( i, elm ) {
            let id         = $( elm ).attr( 'name' ) + '-' + i
            let label_text = $( elm ).siblings( '.wpcf7-list-item-label' ).text()
            let label      = '<label for="' + id + '">' + label_text + '</label>'

            $( elm ).siblings( '.wpcf7-list-item-label' ).remove()
            $( elm ).parent().append( label )
        } )
    }
    
    customizeSelect()
    {
        $( 'select' ).parent().not( '.formSelect' ).children( 'select' ).wrap( '<div class="formSelect"></div>' )
    }
}