import L from 'leaflet';


export class MapCpnt {
    constructor()
    {
        this.initializeMap();
    }
    
    initializeMap()
    {
        this.initializeApi( 'js-map' );
    }
    
    initializeApi( $el )
    {
        if ( document.body.contains( document.getElementById( $el ) ) ) {
            let addresses = [
                {
                    latitude: Theme.latitude,
                    longitude: Theme.longitude,
                    title: Theme.name,
                    street: Theme.street,
                    postalcode: Theme.postalcode,
                    city: Theme.city
                }
            ];
            
            let map = L.map( $el, {
                center: [
                    parseFloat( Theme.latitude ),
                    parseFloat( Theme.longitude )
                ],
                zoom: 11,
                scrollWheelZoom: false
            } );
            
            L.tileLayer( 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            } ).addTo( map );
            
            let marker_bounds = [];
            let self          = this;
            
            $.each( addresses, function ( index, data ) {
                
                let marker_icon = L.icon( {
                                              iconUrl: Theme.themeURL + '/assets/dist/images/markers/marker.svg',
                                              iconSize: [
                                                  33,
                                                  33
                                              ]
                                          } );
                
                let marker = L.featureGroup()
                              .addLayer( L.marker( [
                                                       parseFloat( data.latitude ),
                                                       parseFloat( data.longitude )
                                                   ], { icon: marker_icon } ) )
                              .bindPopup( self.getInfoboxContent( data ), {
                                  className: 'light',
                                  autoPanPadding: L.point( 30, 30 ),
                              } )
                              .addTo( map )
                              .openPopup();
                
                marker_bounds.push( [
                                        parseFloat( data.latitude ),
                                        parseFloat( data.longitude )
                                    ] );
                
            } );
        }
    }
    
    getInfoboxContent( data )
    {
        
        let html = '<div class="infoBoxContent">' +
                   '<p class="infoBoxContentTitle">' + data.title + '</p>';
        
        if ( data.street || data.postalcode || data.city ) {
            
            html += '<p class="infoBoxContentExcerpt"><i class="fas fa-map-marker-alt"></i> ' +
                    data.street + '<br>' +
                    data.postalcode + ' ' + data.city + '<br>' +
                    '</p>';
        }
        
        html += '<p>' +
                '<a class="itinerary" href="https://www.google.com/maps/dir/Ma+position/' + parseFloat( data.latitude ) + ',' + parseFloat( data.longitude ) + '" target="_blank"><i class="fas fa-location-arrow"></i>' + Theme.translate.calculate_my_itinerary + '</a>' +
                '</p>' +
                '</div>';
        
        return html;
    };
}